import React from 'react';
import { Link } from 'gatsby';
import Seo from '../components/Seo';
import { indexHolder } from './index.module.scss';

export default function IndexPage() {
  return (
    <article className={indexHolder}>
      <h2>A collaboration of 10 forward thinking production companies spread across the globe</h2>
      <ul className="homeLinks">
        <li>
          <Link to="/what-we-are" title='What is "The Creatives"?'>
            What We Are
          </Link>
        </li>
        <li>
          <Link to="/who-we-are/haut-et-court" title="Which companies are The Creatives comprised of?">
            Who We Are
          </Link>
        </li>
      </ul>
    </article>
  );
}

export const Head = () => <Seo title="Home" />;
